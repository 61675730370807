<template>
  <div class="ownerCarList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="导入" permission="add" @click="goPageImport"></v-button>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="录入时间段" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate" defaultTimeType="month"></v-datepicker>
        <v-input label="车牌号" v-model="searchParams.carNum"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-input label="用户姓名" v-model="searchParams.ownerName"></v-input>
        <v-select label="状态" v-model="searchParams.carStatus" :options="statusOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
    <el-drawer v-if="subPageType == 'drawer'" :wrapperClosable="false" append-to-body  size="80%" destroy-on-close :visible.sync="showDrawer">
      <owner-car-form @closeDrawer="closeSubDrawer" :editQuery='editQuery'/>
    </el-drawer>
  </div>
</template>

<script>
import { getListURL, exportListURL, getOrgListURL, batchURL } from './api'
import { statusMap } from './map'
import OwnerCarForm from './OwnerCarForm.vue'

export default {
  name: 'OwnerCarList',
  data () {
    return {
      showDrawer:false,
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '正常',
          value: 0
        }, {
          text: '关闭',
          value: 1
        }
      ],
      orgParams: {
        searchUrl: getOrgListURL,
        response: {
          text: 'orgName'
        }
      },
      searchParams: {
        startDate: '',
        endDate: '',
        carNum: '',
        mobileNum: '',
        ownerName: '',
        carStatus: undefined
      },
      headers: [
        {
          prop: 'carNum',
          label: '车牌号'
        },
        {
          prop: 'ownerName',
          label: '用户姓名'
        },
        {
          prop: 'mobileNum',
          label: '用户手机号'
        },
        {
          prop: 'orgName',
          label: '状态',
          formatter (row) {
            return statusMap[row.carStatus]
          }
        },
        {
          prop: 'intime',
          label: '录入时间'
        },
        {
          prop: 'updateUser',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        }
      ]
    }
  },
  components:{
    OwnerCarForm
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  methods: {
    closeSubDrawer(){
      this.showDrawer =false
      this.$emit('contact',{
        method:'getDetailData',
        data:() => {
          this.showComponent = true
        }
      })
    },
    updateStatus (ids, batchURL) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        ids: _ids,
        dataObject: _ids
      }
      _this.$axios.post(batchURL, postData).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    batch (selected) {
      this.updateStatus(selected.ids, batchURL)
    },
    create () {
       if(this.subPageType == 'drawer'){
        this.showDrawer =true
      }else{
        this.$router.push({
          name: 'ownerCarForm',
        })
      }
    },
    edit (row) {
       if(this.subPageType == 'drawer'){
        this.editQuery = {id:row.id}
        this.showDrawer =true
      }else{
        this.$router.push({
          name: 'ownerCarForm',
          query: {
            id: row.id
          }
        })
      }
    },
    goPageImport () {
      this.$router.push({
        name: 'ownerCarImport'
      })
    }
  }
}
</script>
